type ENV = 'local'|'dev' | 'prod' | 'demo'



export const currentEnv: ENV = "demo";


const env: ENV = currentEnv;

export const Env : string = env

const API_DEV_URL = "https://kanimmo-api.withvolkeno.com";
const API_DEMO_URL = "https://back.staging.kanimmo.com";
const API_PROD_URL = "https://api.prod.kanimmo.com";
// const APP_URL_LACAL = "http://0.0.0.0:8000";

export const APP_URL_DEV = "https://kanimmo-dev.withvolkeno.com";
export const APP_URL_DEMO = "https://www.staging.kanimmo.com";
export const APP_URL_PROD = "https://www.kanimmo.com";


function processApiUrl() {
	if (env === "prod") return API_PROD_URL;
	if (env === "demo") return API_DEMO_URL;
	if (env === "dev") return API_DEV_URL;
	// if (env === "local") return APP_URL_LACAL;

	return API_DEMO_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}
export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();
